const ColorEnum = {
  SAND: "#d8836e",
  BLUE: "#78a2cb",
  PURPLE: "#c092b9",
}

const customColors = [ColorEnum.SAND, ColorEnum.BLUE, ColorEnum.PURPLE]

const getRandomColor = () => {
  return customColors[Math.floor(Math.random() * customColors.length)]
}

module.exports = {
  author: "@ismaelmt",
  siteTitle: "Ismael Mendonça",
  siteShortTitle: "Isma's landing", // Used as logo text in header, footer, and splash screen
  siteDescription: "Welcome to my landing page",
  siteUrl: "https://ismaelmendonca.netlify.app/",
  siteLanguage: "en_US",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "Isma's Portfolio", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"

  splashScreen: false, // Set this to true if you want to use the splash screen

  shownArticles: 1,

  colors: {
    lightTheme: {
      // primary: "#000000",
      primary: getRandomColor(),
      secondary: "#FFF4D9",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      // primary: "#FAFAFA",
      primary: getRandomColor(),
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
}
