import React, { useState } from "react"
import PropTypes from "prop-types"

import Context from "."

const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState({
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: true,
    darkMode: false,
  })

  return <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
}

GlobalStateProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default GlobalStateProvider
